import { get } from './apicaller';

export const getPotentialPlacesByAddress = (/*value*/) => Promise.resolve({ data: [] });
// post(`${process.env.REACT_APP_BACKEND_URL}/api/utils/address`, { address: value }, []);

export const getPlaceDetailsById = id =>
  get(`${process.env.REACT_APP_BACKEND_URL}/api/utils/address/${id}/details`, []);

export const getCurrencies = () => get(`${process.env.REACT_APP_BACKEND_URL}/currencies`, []);

export const getBanners = () => get(`${process.env.REACT_APP_BACKEND_URL}/api/banners`, []);

export const getInstrumentTypes = () =>
  get(`${process.env.REACT_APP_BACKEND_URL}/api/instrument-types`, []);
