import React from 'react';
import { constants, EmailCode, Enum } from '@utocat/catalizr-const-sharing-lib';

// API
import { getInvestmentByLinkId } from '../api/investment.api';
import { getInvestmentFurthestStep } from '../../pages/dashboard/common/api/dashboard.api';

// utils
import {
  SIGN_DOCUMENTS_ENTITY_TYPE,
  PROGRESS_BAR_STATUS,
  PROGRESS_BAR_STATUS_DISPOSAL_OPERATION,
  PROGRESS_BAR_STATUS_PRIVATE_EQUITY_OPERATION,
} from './CatalizrConstants';
import {
  isCompanyFinalStage,
  getStageFromJourney,
  getCurrentUserType,
  getCurrentStage,
  isForwardModeForInvestorStage,
  isForwardModeForCompanyStage2,
} from './JourneyUtils';
import { disposal, repurchase, privateEquity } from './OperationUtils';
import { translateEmailStatus, translateUserRole } from './translation-utils';

import { getStore } from '../../store';

// Assets
import IconEmailDelivered from '../../iconsPictos/iconEmailDelivered';
import IconEmailOpened from '../../iconsPictos/iconEmailOpened';
import IconEmailClicked from '../../iconsPictos/iconEmailClicked';
import IconEmailNoStatus from '../../iconsPictos/iconEmailNoStatus';
import IconEmailProblem from '../../iconsPictos/iconEmailProblem';
import { intl } from '../../intlConfig';
import dayjs from 'dayjs';

// --------------------------------------------------------------------------------------------
// List of methods relating to document processing
// --------------------------------------------------------------------------------------------

/**
 * Filter the list of documents to only return the documents to upload and the ones not yet uploaded if
 * the investment is set.
 * @param {array} documentsList the list of documents to filter
 * @param {object} investmentData the investment data (default : null)
 * @param {boolean} uploadAttestationLetter flag indicating if the "Lettre d'attestation" should be uploaded (default : false)
 */
export const filterDocsToUpload = ({
  documentsList,
  investmentData = null,
  uploadAttestationLetter,
}) => {
  // filter documents to upload
  let docsToUpload = documentsList
    ? documentsList.filter(document =>
        (document.type_document === Enum.Documents.Type.RETURN_ATTESTATION ||
          document.type_document === Enum.Documents.Type.RETURN_ATTESTATION_APPORT_TITRES) &&
        isCompanyFinalStage()
          ? uploadAttestationLetter
          : document.origin === 'upload',
      )
    : [];
  // filter documents that was previously uploaded
  if (investmentData && investmentData.documents && docsToUpload.length > 0) {
    docsToUpload = docsToUpload.filter(
      document =>
        !investmentData.documents.find(doc => doc.type_document === document.type_document),
    );
  }
  return docsToUpload;
};

/**
 * Filter the list of documents to only return the documents to upload
 * @param options
 */
export const filterDocsToUploadNew = options => {
  let { documentsList, uploadAttestationLetter } = options;
  // filter documents to upload
  let docsToUpload = documentsList
    ? documentsList.filter(document =>
        (document.type_document === Enum.Documents.Type.RETURN_ATTESTATION ||
          document.type_document === Enum.Documents.Type.RETURN_ATTESTATION_APPORT_TITRES) &&
        isCompanyFinalStage()
          ? uploadAttestationLetter
          : (document.origin === 'upload' || document.origin === 'uploaded') &&
            document.type_document !== Enum.Documents.Type.PROOF_OF_AUTHORITY,
      )
    : [];
  if (isForwardModeForInvestorStage()) {
    const generatedFiles = documentsList.filter(
      document =>
        document.originDocTree === 'generated' &&
        Array.isArray(document.signatory_for) &&
        document.signatory_for.includes(Enum.Documents.SignatoryType.INVESTOR) &&
        !(document.origin === 'upload' || document.origin === 'uploaded'),
    );
    docsToUpload = docsToUpload.concat(generatedFiles);
  } else if (isForwardModeForCompanyStage2()) {
    const generatedFiles = documentsList.filter(
      document =>
        document.originDocTree === 'generated' &&
        document.type_document !== Enum.Documents.Type.RETURN_ATTESTATION &&
        document.type_document !== Enum.Documents.Type.RETURN_ATTESTATION_APPORT_TITRES &&
        !(document.origin === 'upload' || document.origin === 'uploaded'),
    );
    docsToUpload = docsToUpload.concat(generatedFiles);
  }
  // Set OTHER document at the end of the array if it's present
  // To remove when we will create display order on document_types table (Bank DB)
  const documentOtherIndex = docsToUpload.findIndex(
    doc => doc.type_document === Enum.Documents.Type.OTHER,
  );
  if (documentOtherIndex !== -1) {
    const docToMove = docsToUpload.splice(documentOtherIndex, 1)[0];
    docsToUpload.splice(docsToUpload.length, 0, docToMove);
  }
  return docsToUpload;
};

export const isDocumentOptional = (mandatory, userType) => {
  if (mandatory && mandatory.length && userType) {
    return !mandatory.includes(userType.toUpperCase());
  }
  return true;
};

/**
 * Know if a document is mandatory or not
 * @param {array} mandatory the list of account type the document is mandafory for
 */
export const isDocumentMandatory = mandatory =>
  mandatory.length ? mandatory.includes(getCurrentUserType().toUpperCase()) : false;

/**
 * Get user who is mandatory to upload a document
 * @param {array} mandatory the list of user type the document is mandatory for
 * @returns {string} user label ("l'entreprise", "l'acheteur" ou "l'investisseur") according to the user type ("COMPANY" or "INVESTOR")
 */
export const getUserMandatoryForDocument = mandatory => {
  let userLabel = "l'entreprise";
  if (mandatory.length) {
    if (mandatory[0] === 'INVESTOR' && repurchase()) {
      // For repurchase, the investor is the buyer
      userLabel = "l'acheteur";
    } else if (mandatory[0] === 'COMPANY') {
      userLabel = "l'entreprise";
    } else {
      userLabel = "l'investisseur";
    }
  }
  return userLabel;
};

/**
 * If we upload AttestationLetter (aka RETURN_ATTESTATION) we don't sign it.
 * passing options.signAttestationLetter at true to sign/return RETURN_ATTESTATION.
 * @param {*} investmentLinkId
 * @param {*} options
 */
export const getDocsForPreview = async (investmentLinkId, options = {}) => {
  // We set userType to investor for advisor user for his preview (advisor doesn't sign anything)
  let type = SIGN_DOCUMENTS_ENTITY_TYPE[options.userType] || 'INVESTOR';
  let signAttestationLetter =
    options.signAttestationLetter !== undefined ? options.signAttestationLetter : true;

  try {
    const {
      data: { data: investment },
    } = await getInvestmentByLinkId(investmentLinkId, {
      signatory: type,
      stage: getStageFromJourney(options.journey),
    });
    const filteredDocuments = investment.documents.filter(doc => {
      if (!doc.id || !doc.signatory_for?.length) {
        return false;
      }
      if (
        signAttestationLetter === false &&
        [
          Enum.Documents.Type.RETURN_ATTESTATION,
          Enum.Documents.Type.RETURN_ATTESTATION_APPORT_TITRES,
        ].includes(doc.type_document)
      ) {
        return false;
      }
      if (
        Array.isArray(doc.signatory_for) &&
        doc.signatory_for.includes(Enum.Documents.SignatoryType.INVESTOR_COMPANY)
      ) {
        return true;
      }
      return doc.envelope_status !== 'SIGNED';
    });
    return { filteredDocuments, fullResult: investment };
  } catch (error) {
    console.error(
      "Une erreur s'est produite lors de la récupération des documents de preview :",
      error,
    );
    return { filteredDocuments: [], fullResponse: null };
  }
};

/*
 ** options.action can be 'signature' or 'read'. We can read or sign a document.
 */
export const filterDocsForAction = (docs, options) =>
  docs.filter(doc => doc && doc.id && doc.action === options.action);

/**
 * Indicates if the client has chosen to upload his own attestation letter.
 * @returns {boolean} true if client has chosen to upload his attestation letter otherwise false.
 */
export const hasClientChosenToUploadAttestationLetter = () => {
  return isCompanyFinalStage() && getStore().getState().default.uploadAttestationLetter;
};

/**
 * Checks if current user already signed his documents according to furthest step
 * @param {string} investmentLinkId Current investmentLinkId
 * @param documentsToSign
 * @returns {boolean} Returns true if furthest step matches with step set after user signature
 */
export const areDocumentsAlreadySigned = async (investmentLinkId, documentsToSign) => {
  let documentsAreSigned = false;
  let result = await getInvestmentFurthestStep(investmentLinkId);

  // WAITING_FIRST_ACTION step means investor signed his document and investment is waiting for company first action
  // WAITING_DOCUSIGN_CALLBACK means that the user signed his document but Docusign has not confirmed it yet
  // SIGN_OK means esign provider confirmed user's signature
  if (
    result.data.step === constants.Steps.WAITING_FIRST_ACTION.code ||
    result.data.step === constants.Steps.WAITING_DOCUSIGN_CALLBACK.code ||
    result.data.step === constants.Steps.SIGN_OK.code
  ) {
    if (
      result.data.step === constants.Steps.WAITING_FIRST_ACTION.code &&
      documentsToSign.length > 0
    ) {
      return false;
    }
    documentsAreSigned = true;
  }

  return documentsAreSigned;
};

// --------------------------------------------------------------------------------------------
// List of other methods that remain to be categorized
// --------------------------------------------------------------------------------------------

/**
 * Returns default selected bank name according to the subdomain.
 * @returns {string} the bank name linked to the user
 */
export const selectDefaultBankName = () => getStore().getState().bank.name;

/**
 * Checks if the given string is not empty/blank.
 * @param {string} _string
 * @returns {boolean}
 */
export const isNotBlank = _string => _string && _string !== '';

/**
 * Checks if the given feature is allowed for this client.
 * @param {string} featureToCheck Feature to check if it's enabled or not
 * @returns {boolean}
 */
export const isFeatureEnabled = featureToCheck => {
  const { features, featuresAsInvestor } = getStore().getState().bank; // featuresAsCompany
  if (isForwardModeForInvestorStage() && Array.isArray(featuresAsInvestor)) {
    return featuresAsInvestor && featuresAsInvestor.includes(featureToCheck);
  }
  return features && features.includes(featureToCheck);
};

/**
 * Returns the user stored into redux.
 * @returns {object} data related to the connected user
 */
export const getConnectedUser = () => getStore().getState().user;

export const getAppLocale = () => getStore().getState().user.locale;

/**
 * Return the main investor email for an investment stored into redux.
 * @returns {object} data related to the investment
 */
export const getMainInvestorEmail = () => getStore().getState().investment.first_investor_email;

/**
 * Returns email icon and email status value to display emails in dashboard timeline
 * @param {string} status The email status from Mailjet
 * @returns {object} With two properties (email icon and status)
 */
export const getEmailInfosTimeline = status => {
  const emailDatasNeededForTimeline = {
    icon: '',
    status: translateEmailStatus(status || 'none'),
  };
  switch (status) {
    case Enum.EmailStatus.SENT:
      emailDatasNeededForTimeline.icon = (
        <IconEmailDelivered
          alt={emailDatasNeededForTimeline.status}
          title={emailDatasNeededForTimeline.status}
          width="35px"
          height="35px"
          active={true}
        />
      );
      break;
    case Enum.EmailStatus.OPEN:
      emailDatasNeededForTimeline.icon = (
        <IconEmailOpened
          alt={emailDatasNeededForTimeline.status}
          title={emailDatasNeededForTimeline.status}
          width="35px"
          height="35px"
        />
      );
      break;
    case Enum.EmailStatus.CLICK:
      emailDatasNeededForTimeline.icon = (
        <IconEmailClicked
          alt={emailDatasNeededForTimeline.status}
          title={emailDatasNeededForTimeline.status}
          width="30px"
          height="30px"
        />
      );
      break;
    case Enum.EmailStatus.UNSUB:
      emailDatasNeededForTimeline.icon = (
        <IconEmailProblem
          alt={emailDatasNeededForTimeline.status}
          title={emailDatasNeededForTimeline.status}
          width="35px"
          height="35px"
        />
      );
      break;
    case Enum.EmailStatus.BLOCKED:
      emailDatasNeededForTimeline.icon = (
        <IconEmailProblem
          alt={emailDatasNeededForTimeline.status}
          title={emailDatasNeededForTimeline.status}
          width="35px"
          height="35px"
        />
      );
      break;
    case Enum.EmailStatus.BOUNCE:
      emailDatasNeededForTimeline.icon = (
        <IconEmailProblem
          alt={emailDatasNeededForTimeline.status}
          title={emailDatasNeededForTimeline.status}
          width="35px"
          height="35px"
        />
      );
      break;
    case Enum.EmailStatus.SPAM:
      emailDatasNeededForTimeline.icon = (
        <IconEmailProblem
          alt={emailDatasNeededForTimeline.status}
          title={emailDatasNeededForTimeline.status}
          width="35px"
          height="35px"
        />
      );
      break;
    default:
      emailDatasNeededForTimeline.status = translateEmailStatus('none');
      emailDatasNeededForTimeline.icon = (
        <IconEmailNoStatus
          alt={emailDatasNeededForTimeline.status}
          title={emailDatasNeededForTimeline.status}
          width="35px"
          height="35px"
        />
      );
      break;
  }
  return emailDatasNeededForTimeline;
};

/**
 * Return status if it's include on investment history.
 * @param {string} status Status to check
 * @returns {array} statusFind (Array)
 */
export const checkStatusOnHistory = status => {
  const history = getStore().getState().investment.history;
  const statusFind = [];
  if (Array.isArray(history)) {
    history.forEach(step => {
      if (step.status.includes(status)) {
        statusFind.push(step);
      }
    });
  }
  return statusFind;
};

const createTimeline = (investmentType, portfolioType, updateDocuments) => {
  let timelineSteps = [];
  // step 1 - LE - 20%
  const step1Title =
    investmentType === Enum.Operations.Type.DISPOSAL
      ? intl.formatMessage({ id: 'investment_infos.timeline.step1_disposal' })
      : investmentType === Enum.Operations.Type.PRIVATE_EQUITY
      ? intl.formatMessage({ id: 'investment_infos.timeline.step1_private_equity' })
      : intl.formatMessage({ id: 'investment_infos.timeline.step1' });
  const step1Emails =
    investmentType === Enum.Operations.Type.DISPOSAL
      ? [
          EmailCode.Investor.INVESTOR_INFO_OPERATION_REJECTED,
          EmailCode.Investor.INVESTOR_INFO_DISPOSAL_TRANSMIT_LOGIN,
        ]
      : [
          EmailCode.Investor.INVESTOR_INFO_OPERATION_REJECTED,
          EmailCode.Investor.INVESTOR_INFO_ACCOUNT_LOGIN,
        ];
  if (portfolioType === Enum.Accounts.PortfolioType.CTJ) {
    timelineSteps.push({
      stepIndex: 1,
      title: step1Title,
      userType: intl.formatMessage({ id: 'investment_infos.timeline.roles.investor1' }),
      stepStatus: [Enum.Operations.Status.WAITING_DOCUMENT],
      callToAction_template_code: step1Emails,
      reminder_template_code: 'investorReminders',
    });
    // step 1 - LE - 30%
    // FIXME update with new email
    const step1EmailsCoInvestor =
      investmentType === Enum.Operations.Type.DISPOSAL
        ? [EmailCode.Investor.INVESTOR_INFO_DISPOSAL_TRANSMIT_LOGIN]
        : [EmailCode.Investor.INVESTOR_INFO_ACCOUNT_LOGIN];
    timelineSteps.push({
      stepIndex: 1,
      title: intl.formatMessage({ id: 'investment_infos.timeline.step1_co_investor_2' }),
      userType: intl.formatMessage({ id: 'investment_infos.timeline.roles.investor2' }),
      stepStatus: [Enum.Operations.Status.CO_INVESTOR_SIGNED],
      callToAction_template_code: step1EmailsCoInvestor,
      reminder_template_code: 'investorReminders',
    });
  } else {
    timelineSteps.push({
      stepIndex: 1,
      title: step1Title,
      userType: translateUserRole(Enum.User.Role.INVESTOR_ROLE),
      stepStatus: [Enum.Operations.Status.WAITING_DOCUMENT],
      callToAction_template_code: step1Emails,
      reminder_template_code: 'investorReminders',
    });
  }
  // step 2 - LI - 40%
  if (
    ![
      Enum.Operations.Type.DISPOSAL,
      Enum.Operations.Type.KIND_CONTRIBUTION,
      Enum.Operations.Type.PRIVATE_EQUITY,
    ].includes(investmentType)
  ) {
    timelineSteps.push({
      stepIndex: 2,
      title: intl.formatMessage({ id: 'investment_infos.timeline.step2' }),
      userType: translateUserRole(Enum.User.Role.COMPANY_ROLE),
      stepStatus: [
        Enum.Operations.Status.INVESTOR_SIGNED,
        Enum.Operations.Status.PAYMENT_TO_BE_MADE,
        Enum.Operations.Status.PAYMENT_EMITTED,
      ],
      callToAction_template_code: [EmailCode.Company.COMPANY_INFO_ACCOUNT_LOGIN],
      reminder_template_code: 'companyS1Reminders',
    });
  } else if (Enum.Operations.Type.PRIVATE_EQUITY === investmentType) {
    timelineSteps.push({
      stepIndex: 2,
      title: intl.formatMessage({ id: 'investment_infos.timeline.step2_private_equity' }),
      stepStatus: [Enum.Operations.Status.INVESTOR_SIGNED],
      userType: intl.formatMessage({ id: 'investment_infos.timeline.roles.management_company' }),
      callToAction_template_code: [EmailCode.Company.COMPANY_INFO_ACCOUNT_LOGIN],
      reminder_template_code: 'companyS1Reminders',
    });
  }
  // step 3 - Payment to do by bank - 60%
  if (
    ![
      Enum.Operations.Type.DISPOSAL,
      Enum.Operations.Type.KIND_CONTRIBUTION,
      Enum.Operations.Type.PRIVATE_EQUITY,
    ].includes(investmentType)
  ) {
    timelineSteps.push({
      stepIndex: 3,
      title: intl.formatMessage({ id: 'investment_infos.timeline.step3' }),
      userType: intl.formatMessage({ id: 'investment_infos.timeline.roles.bank' }),
      stepStatus: [
        Enum.Operations.Status.INVESTOR_SIGNED,
        Enum.Operations.Status.PAYMENT_TO_BE_MADE,
      ],
      callToAction_template_code: [EmailCode.Company.COMPANY_INFO_OPERATION_BANK_PAYMENT_EMITTED],
    });
  } else if (Enum.Operations.Type.DISPOSAL === investmentType) {
    timelineSteps.push({
      stepIndex: 3,
      title: intl.formatMessage({ id: 'investment_infos.timeline.step3_disposal' }),
      stepStatus: [],
      userType: intl.formatMessage({ id: 'investment_infos.timeline.roles.bank' }),
      callToAction_template_code: [],
    });
  }
  // step 4 - Payment to confirmed by company
  if (
    ![
      Enum.Operations.Type.DISPOSAL,
      Enum.Operations.Type.KIND_CONTRIBUTION,
      Enum.Operations.Type.PRIVATE_EQUITY,
    ].includes(investmentType)
  ) {
    const step4Title =
      investmentType === Enum.Operations.Type.REPURCHASE
        ? intl.formatMessage({ id: 'investment_infos.timeline.step4_repurchase' })
        : intl.formatMessage({ id: 'investment_infos.timeline.step4' });
    timelineSteps.push({
      stepIndex: 4,
      title: step4Title,
      userType: translateUserRole(Enum.User.Role.COMPANY_ROLE),
      stepStatus: [Enum.Operations.Status.ALL_SIGNED, Enum.Operations.Status.PAYMENT_EMITTED],
      callToAction_template_code: [EmailCode.Company.COMPANY_INFO_OPERATION_STAGE_2],
      reminder_template_code: 'companyS2Reminders',
    });
  }
  // step 5 - Attestation - 80%
  if (
    ![Enum.Operations.Type.DISPOSAL, Enum.Operations.Type.PRIVATE_EQUITY].includes(investmentType)
  ) {
    const step5Title =
      !updateDocuments.find(x => x.type_document === 'RETURN_ATTESTATION') &&
      isFeatureEnabled('display.private_equity')
        ? "Signature de l'ordre de mouvement"
        : intl.formatMessage({ id: 'investment_infos.timeline.step5' });
    const step5Emails =
      investmentType === Enum.Operations.Type.KIND_CONTRIBUTION
        ? [
            EmailCode.Company.COMPANY_INFO_OPERATION_STAGE_2,
            EmailCode.Company.COMPANY_INFO_OPERATION_REJECTED,
          ]
        : [EmailCode.Company.COMPANY_INFO_OPERATION_REJECTED];
    timelineSteps.push({
      stepIndex: 5,
      title: step5Title,
      userType: translateUserRole(Enum.User.Role.COMPANY_ROLE),
      stepStatus: [Enum.Operations.Status.PAYMENT_RECEIVED],
      callToAction_template_code: step5Emails,
    });
  }
  // step 6 - BO conformity - 95%
  const step6Title =
    investmentType === Enum.Operations.Type.DISPOSAL
      ? intl.formatMessage({ id: 'investment_infos.timeline.step6_disposal' })
      : intl.formatMessage({ id: 'investment_infos.timeline.step6' });
  timelineSteps.push({
    stepIndex: 6,
    title: step6Title,
    userType: intl.formatMessage({ id: 'investment_infos.timeline.roles.bank' }),
    stepStatus: [Enum.Operations.Status.INVESTMENT_UPDATED],
  });
  // final step - close / cancelled - 100%
  const stepFinalEmails =
    investmentType === Enum.Operations.Type.DISPOSAL
      ? [
          EmailCode.Investor.INVESTOR_INFO_OPERATION_CANCELLED,
          EmailCode.Investor.INVESTOR_INFO_OPERATION_CLOSED,
        ]
      : [
          EmailCode.Investor.INVESTOR_INFO_OPERATION_CANCELLED,
          EmailCode.Investor.INVESTOR_INFO_OPERATION_CLOSED,
          EmailCode.Company.COMPANY_INFO_OPERATION_CLOSED,
          EmailCode.Company.COMPANY_INFO_OPERATION_CANCELLED,
        ];
  timelineSteps.push({
    stepIndex: 7,
    userType: '',
    stepStatus: [Enum.Operations.Status.INVESTMENT_CLOSED, Enum.Operations.Status.CANCELLED],
    callToAction_template_code: stepFinalEmails,
  });
  return timelineSteps;
};

/**
 * Returns datas needed to display timeline steps.
 * @param {array} emails Array of emails sent to investor and company for one investment
 * @param {string} currentInvestmentStatus Status of current investment
 * @param updateDocuments
 * @param {string} investmentType operation type
 * @param {string} portfolioType portfolio type
 * @param {boolean} isIneligible
 */
export const getDashboardTimeline = (
  emails,
  currentInvestmentStatus,
  updateDocuments,
  investmentType,
  portfolioType,
  isIneligible,
) => {
  let timelineSteps = createTimeline(investmentType, portfolioType, updateDocuments);
  // Copy TIMELINE_STEPS array to assign new properties
  timelineSteps = structuredClone(timelineSteps);
  const mainInvestorEmail = getMainInvestorEmail();

  const currentStage = getCurrentStage();
  // check for ALL_SIGNED status on investment history stage 1
  const isAllSignedOnHistory = checkStatusOnHistory(Enum.Operations.Status.ALL_SIGNED);
  const isCompanySignedFirstStageDoc =
    currentStage === 1 &&
    isAllSignedOnHistory &&
    isAllSignedOnHistory[0] &&
    isAllSignedOnHistory[0].status === Enum.Operations.Status.ALL_SIGNED;

  // check for PAYMENT_EMITTED status on investment history stage 1
  const isEmittedPaymentOnHistory = checkStatusOnHistory(Enum.Operations.Status.PAYMENT_EMITTED);
  const isBankAlreadyValidTransfer =
    currentStage === 1 &&
    isEmittedPaymentOnHistory &&
    isEmittedPaymentOnHistory[0] &&
    isEmittedPaymentOnHistory[0].status === Enum.Operations.Status.PAYMENT_EMITTED;

  // array containing the index(es) of the active step(s) according to investment status and stepStatus property in TIMELINE_STEPS
  // an index refers to the position of the active step in the timeline (TIMELINE_STEPS)
  const activeTimelineSteps = [];

  // if investment status is INVESTMENT_UPDATED, all steps are complete
  if (currentInvestmentStatus === Enum.Operations.Status.INVESTMENT_CLOSED) {
    activeTimelineSteps.push(timelineSteps.length);
  } else {
    // determines the active step(s) of the timeline (according to the investment status)
    timelineSteps.forEach((currentTimelineStep, currentIndex) => {
      // disable company STEP2 if company ALL_SIGNED before bank PAYMENT_EMITTED
      if (
        currentTimelineStep.stepIndex === 2 &&
        currentTimelineStep.stepStatus.includes(Enum.Operations.Status.PAYMENT_EMITTED) &&
        isCompanySignedFirstStageDoc
      ) {
        currentTimelineStep.stepStatus.pop();
      }
      // disable company STEP2 if company ALL_SIGNED before bank PAYMENT_TO_BE_MADE
      if (
        currentTimelineStep.stepIndex === 2 &&
        currentTimelineStep.stepStatus.includes(Enum.Operations.Status.PAYMENT_TO_BE_MADE) &&
        isCompanySignedFirstStageDoc
      ) {
        currentTimelineStep.stepStatus.splice(1, 1);
      }
      // enable bank STEP3 if company ALL_SIGNED before bank PAYMENT_EMITTED
      if (
        currentTimelineStep.stepIndex === 3 &&
        !currentTimelineStep.stepStatus.includes(Enum.Operations.Status.PAYMENT_EMITTED) &&
        isCompanySignedFirstStageDoc &&
        !isBankAlreadyValidTransfer
      ) {
        currentTimelineStep.stepStatus.push(Enum.Operations.Status.ALL_SIGNED);
      }
      // disable company STEP4 if bank PAYMENT_EMITTED is mandatory before company PAYMENT_RECEIVED
      // fix need to refresh
      if (
        currentTimelineStep.reminder_template_code === 'companyS2Reminders' &&
        isFeatureEnabled('allow.confirm_transfer_received_mandatory') &&
        !isBankAlreadyValidTransfer
      ) {
        currentTimelineStep.stepStatus.shift();
      }
      // disable company STEP4 if bank PAYMENT_EMITTED before company ALL_SIGNED
      // fix need to refresh
      if (
        currentTimelineStep.reminder_template_code === 'companyS2Reminders' &&
        !isCompanySignedFirstStageDoc &&
        isBankAlreadyValidTransfer
      ) {
        currentTimelineStep.stepStatus.pop();
      }
      if (
        currentTimelineStep.stepStatus.includes(currentInvestmentStatus) ||
        (currentInvestmentStatus === Enum.Operations.Status.ALL_SIGNED &&
          currentIndex === 1 &&
          disposal())
      ) {
        activeTimelineSteps.push(currentIndex);
      }
      if (
        currentInvestmentStatus === Enum.Operations.Status.INVESTMENT_UPDATED &&
        currentIndex === 2 &&
        privateEquity()
      ) {
        activeTimelineSteps.push(currentIndex);
      }
    });
  }

  // populates each step of the timeline with the data coming from the investment (status and emails)
  timelineSteps.forEach((currentStep, index) => {
    // 1. determines the completion status of the current timeline step : default, active or complete
    // stored in the status property and used for displaying the color of the step
    activeTimelineSteps.forEach(activeStep => {
      if (index < activeStep && !currentStep.status) {
        currentStep.status = 'complete';
      }
      // if bank PAYLMENT_EMITTED before company ALL_SIGNED we display complete style for bank
      if (index > activeStep && activeStep > 0 && currentStep.stepIndex === 3) {
        currentStep.status = 'complete';
      }
      if (index === activeStep) {
        currentStep.status = 'active';
      }
      if (index > activeStep && !currentStep.status) {
        currentStep.status = 'default';
      }
      if (
        currentStep.stepIndex === 5 &&
        investmentType === Enum.Operations.Type.KIND_CONTRIBUTION &&
        isIneligible
      ) {
        currentStep.status = 'default';
      }
    });

    // 2. determines if the current step has an email corresponding to its callToAction_template_code
    // stored in the ctaEmail property if an email is found (cta => Call to Action)
    if (emails.ctaEmails) {
      let correspondingEmail = [];
      if (currentStep.callToAction_template_code) {
        // A step can now have multiple templates (Last step is "finished" OR "cancelled")
        currentStep.callToAction_template_code.forEach(currentCTA => {
          if (!correspondingEmail.length) {
            // We select only one template by step (first found, first picked)
            correspondingEmail = emails.ctaEmails.filter(currentEmail => {
              // display the matching cta emails for main investor step
              if (currentCTA.includes('INVESTOR')) {
                if (currentStep.stepStatus.includes(Enum.Operations.Status.CO_INVESTOR_SIGNED)) {
                  return (
                    currentCTA === currentEmail.template_code &&
                    currentEmail.to !== mainInvestorEmail
                  );
                }
                return (
                  currentCTA === currentEmail.template_code && currentEmail.to === mainInvestorEmail
                );
              }
              return currentCTA === currentEmail.template_code;
            });
          }
        });
      }

      // We substract 1 for number resend to not count first email sent automatically by Catalizr as a resent email
      currentStep.numberResend =
        correspondingEmail.length === 0 ? 0 : correspondingEmail.length - 1;
      currentStep.ctaEmail = correspondingEmail[0];

      // 2bis. Resend email is allowed if  the feature is enabled and for :
      // case a : the last sent email,
      // case b : the before last email if company did not signed on stage 1
      currentStep.resendIsAllowed =
        (currentStep.ctaEmail === emails.ctaEmails[0] && isFeatureEnabled('allow_resend_email')) ||
        (emails.ctaEmails[1] &&
          isAllSignedOnHistory &&
          isAllSignedOnHistory.length === 0 &&
          isFeatureEnabled('allow_resend_email'));
    }

    // 3. determines if the current step has reminder emails linked to its reminder_template_code
    // stored in the reminderEmails property if emails are found
    if (emails[currentStep.reminder_template_code]) {
      currentStep.reminderEmails = emails[currentStep.reminder_template_code];
    }
  });
  return {
    timelineSteps,
  };
};

/**
 * Get the portfolio type of current investment
 * @returns {string} Returns current portfolio_type (PEA or PEA-PME or Compte titres ordinaire)
 */
export const getCurrentPortfolioType = () => {
  return getStore().getState().investment.currentProduct;
};

/**
 * Current comment to commit
 */
export const getCurrentComment = () => {
  return getStore().getState().investment.currentComment;
};

/**
 * Manage null or undefined value
 * @param {*} value value to check
 * @param {string} replacedValue replaced value if value to check is null or undefined
 */
export const manageNoValue = (value, replacedValue = null) => {
  let valueToSetIfNullOrUndefined = replacedValue ? replacedValue : '-';
  if (value === null || value === undefined) {
    return valueToSetIfNullOrUndefined;
  } else {
    return value;
  }
};

/**
 * Get a label readable by a humain, for a specific progression status
 * @param {*} status
 * @param {*} operationType
 */
export const getProgressionLabelByStatus = (status, operationType) => {
  let mapping =
    operationType === Enum.Operations.Type.DISPOSAL
      ? PROGRESS_BAR_STATUS_DISPOSAL_OPERATION
      : operationType === Enum.Operations.Type.PRIVATE_EQUITY
      ? PROGRESS_BAR_STATUS_PRIVATE_EQUITY_OPERATION
      : PROGRESS_BAR_STATUS;
  const tradKey = mapping[status];
  return tradKey ? intl.formatMessage({ id: tradKey }) : '';
};

/**
 * Get minimum ownership date for kind contribution
 */
export const getMinOwnershipDate = () => {
  const currentPortfolio = getCurrentPortfolioType();
  const minimum = currentPortfolio === Enum.Accounts.PortfolioType.CTO ? 6 : 2;
  return dayjs().subtract(minimum, 'month').toDate();
};
